import React from "react"
import { graphql } from "gatsby"
import ArticlesList from "../components/ArticlesList"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
const TagTemplate = ({ data, pageContext }) => {
  const articles = data.allContentfulProduct.nodes
  return (
    <Layout>
      <Seo title={pageContext.tag} />
      <main className="page">
        <h1 className="tagTitle">{pageContext.tag}</h1>
        <div className="tag-recipes">
          <ArticlesList articles={articles} />
        </div>
      </main>
    </Layout>
  )
}
export const query = graphql`
  query GetProductByTag($tag: String) {
    allContentfulProduct(
      sort: { fields: name, order: ASC }
      filter: { tags: { tags: { eq: $tag } } }
    ) {
      nodes {
        name
        id
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: TRACED_SVG
            width: 500
            height: 500
            cornerRadius: 60
            formats: WEBP

            cropFocus: CENTER
          )
        }
      }
    }
  }
`
export default TagTemplate
