import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import slugify from "slugify"
export const ArticlesList = ({ articles = [] }) => {
  return (
    <div className="recipes-list">
      {articles.map(article => {
        const { id, name, image, featured } = article
        const pathToImage = getImage(image)
        const slug = slugify(name, { lower: true })
        //split the name string into an array of strings
        //whenever a blank space is encountered :
        const nametoUp = name.split(" ")
        //loop through each element of the array and capitalize the first letter.
        for (var i = 0; i < nametoUp.length; i++) {
          nametoUp[i] =
            nametoUp[i].charAt(0).toUpperCase() + nametoUp[i].slice(1)
        }
        //Join all the elements of the array back into a string
        //using a blankspace as a separator
        const nameUp = nametoUp.join(" ")

        return (
          <Link key={id} to={`/${slug}`} className="recipe">
            <GatsbyImage image={pathToImage} alt={name} />
            <h5>
              {nameUp}
              {featured ? "" : "  /SOLD"}
            </h5>
          </Link>
        )
      })}
    </div>
  )
}
export default ArticlesList
